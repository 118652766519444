
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { FC } from 'react';
import dynamic from 'next/dynamic';
import { PlatformID } from '@common/clients/api';
import { prepareProps } from '@web/_app/[contextHash]/prepareProps';
import { Route } from '@web/routing';
import { getNextWebServerSidePropsWrapper, NextWebServerSideProps } from '@web/routing/serverSideProps';
import { HomePage, Props } from '@web/templates/HomePage';
const ViHomePage = dynamic(() => import('@web/templates/HomePage/ViHomePage').then((mod) => mod.ViHomePage));
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.Homepage);
export const Home: FC<Props> = (props) => {
    return props.contextData.platform.id === PlatformID.VI ? (<ViHomePage {...props}/>) : (<HomePage {...props}/>);
};
export default Home;

    async function __Next_Translate__getServerSideProps__19205a87643__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[contextHash]/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19205a87643__ as getServerSideProps }
  